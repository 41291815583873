<footer class="pt-4 pb-4 text-center bg-light">
    <div class="container">
      <div class="my-3">
        <div class="h4">Jayant Bhardwaj</div>
        <p>Application Development Senior-Analyst</p>
        <b>Accenture, India</b>
        <div class="social-nav">
          <nav role="navigation">
            <ul class="nav justify-content-center">
              <!-- <li class="nav-item"><a class="nav-link" href="https://twitter.com/templateflip" title="Twitter"><i class="fab fa-twitter"></i><span class="menu-title sr-only">Twitter</span></a></li> -->
              <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.facebook.com/jayant.bhardwaj.75" title="Facebook"><i class="fab fa-facebook"></i><span class="menu-title sr-only">Facebook</span></a></li>
              <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.instagram.com/jayant.bhardwaj.75/" title="Instagram"><i class="fab fa-instagram"></i><span class="menu-title sr-only">Instagram</span></a></li>
              <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.linkedin.com/in/jayant-bhardwaj-8bb510145" title="LinkedIn"><i class="fab fa-linkedin"></i><span class="menu-title sr-only">LinkedIn</span></a></li>
              <!-- <li class="nav-item"><a class="nav-link" href="https://www.behance.net/templateflip" title="Behance"><i class="fab fa-behance"></i><span class="menu-title sr-only">Behance</span></a></li> -->
            </ul>
          </nav>
        </div>
      </div>
      <div class="text-small text-secondary">
        <!-- <div class="mb-1">&copy; Super Folio. All rights reserved.</div> -->
        <div>
          <!-- Make sure to buy a license for the template before removing the line below. Buy license on https://templateflip.com/ Design - <a href="https://templateflip.com/" target="_blank">TemplateFlip</a>--></div>
      </div>
    </div>
    </footer>
    <div id="scrolltop"><a class="btn btn-secondary" href="#top"><span class="icon"><i
        class="fas fa-angle-up fa-x"></i></span></a></div> 