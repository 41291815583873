<div class="section px-3 px-lg-4 pt-5" id="experience">
    <div class="container-narrow">
      <div class="text-center mb-5">
        <h2 class="marker marker-center">Experience</h2>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card mb-3" data-aos="fade-right" data-aos-delay="100">
            <div class="card-header px-3 py-2">
              <div class="d-flex justify-content-between">
                <div>
                  <h3 class="h5 mb-1">Application Development - Senior Analyst</h3>
                  <div class="text-muted text-small">Accenture India. <small>(2021-present)</small></div>
                </div><img src="../../assets/images/Accenture.svg" width="22%" height="70" alt="ui-ux" />
              </div>
            </div>
            <div class="card-body px-3 py-2">
              <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches
                to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
              <p>Organically grow the holistic world view of disruptive innovation via workplace diversity and
                empowerment.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-3" data-aos="fade-left" data-aos-delay="300">
            <div class="card-header px-3 py-2">
              <div class="d-flex justify-content-between">
                <div>
                  <h3 class="h5 mb-1">Sr. Software Developer</h3>
                  <div class="text-muted text-small">Q3 Technologies. <small>(2019-2021)</small></div>
                </div><img src="../../assets/images/Q3Logo.png" width="41%" height="1%"
                  alt="app development" />
              </div>
            </div>
            <div class="card-body px-3 py-2">
              <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches
                to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
              <p>Organically grow the holistic world view of disruptive innovation via workplace diversity and
                empowerment.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-3" data-aos="fade-right" data-aos-delay="200">
            <div class="card-header px-3 py-2">
              <div class="d-flex justify-content-between">
                <div>
                  <h3 class="h5 mb-1">Software Developer</h3>
                  <div class="text-muted text-small">DotSquares technologies. <small>(2017-2019)</small></div>
                </div><img src="../../assets/images/Dots.png" width="35%" height="1%"
                  alt="web design" />
              </div>
            </div>
            <div class="card-body px-3 py-2">
              <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches
                to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
              <p>Organically grow the holistic world view of disruptive innovation via workplace diversity and
                empowerment.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-3" data-aos="fade-left" data-aos-delay="400">
            <div class="card-header px-3 py-2">
              <div class="d-flex justify-content-between">
                <div>
                  <h3 class="h5 mb-1">Software Developer</h3>
                  <div class="text-muted text-small">Anand Rathi. <small>(2015-2017)</small></div>
                </div><img src="../../assets/images/Arit.png" width="35%" height="1%"
                  alt="full stack" />
              </div>
            </div>
            <div class="card-body px-3 py-2">
              <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches
                to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
              <p>Organically grow the holistic world view of disruptive innovation via workplace diversity and
                empowerment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>