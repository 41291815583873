<div class="section px-3 px-lg-4 pt-5" id="testimonials">
    <div class="container-narrow">
      <div class="text-center mb-5">
        <h2 class="marker marker-center">Testimonials</h2>
      </div>
      <div class="row">
        <div class="col-md-6 mb-5" data-aos="fade-left" data-aos-delay="300">
          <div class="d-flex ms-md-3"><span><i class="fas fa-2x fa-quote-left"></i></span><span class="m-2">
            During my years in Anand Rathi, I had the pleasure of working on the same project as of Jayant's. He always kept the team motivated and shared knowledge. I believe he is one of the best mentor and Software Developer I ever encountered..
He is truly a gem. 
          </span></div>
          <div class="d-flex justify-content-end align-items-end">
            <div class="text-end me-2">
              <div class="fw-bolder">Krishna Gopal Gupta</div>
              <div class="text-small">Sr. Software Developer
                <br/> <b>@Pratham Software, Jaipur</b>
              </div>
            </div><img class="me-md-3 rounded" src="../../assets/images/gopalji.jpeg" width="96"
              height="96" alt="client 1" />
          </div>
        </div>
        <div class="col-md-6 mb-5" data-aos="fade-right" data-aos-delay="100">
          <div class="d-flex ms-md-3"><span><i class="fas fa-2x fa-quote-left"></i></span><span class="m-2">
            Jayant is a fantastic professional who brings all of the skills and expertise in software development and leadership.
He is a professional, helpful, and positive person. It is a pleasure and honor to recommend Jayant to anyone.
He is truly a gem. 
          </span></div>
          <div class="d-flex justify-content-end align-items-end">
            <div class="text-end me-2">
              <div class="fw-bolder">Vinay Rao</div>
              <div class="text-small">Software Development Specialist
               <br/> <b>@Pratham Software</b>
              </div>
            </div><img class="me-md-3 rounded" src="../../assets/images/Vinay.jpg" width="96"
              height="96" alt="client 1" />
          </div>
        </div>
       
        <div class="col-md-6 mb-5" data-aos="fade-right" data-aos-delay="200">
          <div class="d-flex ms-md-3"><span><i class="fas fa-2x fa-quote-left"></i></span><span class="m-2">
            Jayant is very adept and thorough technical professional who brings a wealth of experience in building salable systems. He is very reliable person and always willing to provide a helping hand in a crisis situation.
          </span></div>
          <div class="d-flex justify-content-end align-items-end">
            <div class="text-end me-2">
              <div class="fw-bolder">Basu Sharma</div>
              <div class="text-small">.Net Developer
              <br/>  <b>@Nagarro, Gurugram</b>
              </div>
            </div><img class="me-md-3 rounded" src="../../assets/images/Basu.jpg" width="96"
              height="96" alt="client 1" />
          </div>
        </div>
        <div class="col-md-6 mb-5" data-aos="fade-left" data-aos-delay="400">
          <div class="d-flex ms-md-3"><span><i class="fas fa-2x fa-quote-left"></i></span><span class="m-2">Jayant is
              a great co-worker and problem solver. He is quick to extend his helping hand and makes a good team
              player.</span></div>
          <div class="d-flex justify-content-end align-items-end">
            <div class="text-end me-2">
              <div class="fw-bolder">Sharad Goyal</div>
              <div class="text-small">Team Lead</div>
              <br/>  <b>@ATCS, Jaipur</b>
            </div><img class="me-md-3 rounded" src="../../assets/images/testimonials/client4.jpg" width="96"
              height="96" alt="client 1" />
          </div>
        </div>
      </div>
    </div>
  </div>