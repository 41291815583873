import { Injectable } from '@angular/core';

import Mail from "nodemailer/lib/mailer";
import {async} from 'async'




@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor() { }

 


  
}
