<header>
    <div class="cover bg-light">
      <div class="container px-3">
        <div class="row">
          <div class="col-lg-6 p-2"><img class="img-fluid" src="../../assets/images/illustrations/jdev.gif"
              alt="hello" /></div>
          <div class="col-lg-6">
            <div class="mt-5">
              <!-- <h1>
                <p class="lead text-uppercase mb-1">Hello!  <b>{{ greetingMessage }}</b>  </p>
              </h1> -->
              <div class="bd-slider-five z-index">
                <div class="bd-slider-five-hello mb-30 wow fadeInUp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                  <span class="bd-slider-five-hello-text"><h2>Hey <img src="../../assets/images/4.png" style="height: 50px;" alt="Icon" /> I'm</h2></span>
                 
              </div>
              <h1 class="bd-slider-five-title wow fadeInUp intro-title marker" data-wow-delay=".4s" style="font-size: 68px; visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;text-decoration: underline #EF0454 5%;text-underline-offset:10px;">Jayant Bhardwaj</h1>

              </div>
             
              <!-- <h1 class="intro-title marker" data-aos="fade-left" data-aos-delay="50">Jayant Bhardwaj  </h1> -->
              <p style="font-size: 25px;" class="lead fw-normal mt-3" data-aos="fade-up" data-aos-delay="100"><b>Application Development Senior-Analyst</b>
            <br/> <b>Accenture, India</b></p>
              <div class="social-nav" data-aos="fade-up" data-aos-delay="200">
                <nav role="navigation">
                  <ul class="nav justify-content-left">
                    <!-- <li class="nav-item"><a class="nav-link" href="https://twitter.com/templateflip" title="Twitter"><i class="fab fa-twitter"></i><span class="menu-title sr-only">Twitter</span></a></li> -->
                    <li class="nav-item"><a target="_blank" class="nav-link"
                        href="https://www.facebook.com/jayant.bhardwaj.75" title="Facebook"><i
                          class="fab fa-facebook"></i><span class="menu-title sr-only">Facebook</span></a></li>
                    <li class="nav-item"><a target="_blank" class="nav-link"
                        href="https://www.instagram.com/jayant.bhardwaj.75/" title="Instagram"><i
                          class="fab fa-instagram"></i><span class="menu-title sr-only">Instagram</span></a></li>
                    <li class="nav-item"><a target="_blank" class="nav-link"
                        href="https://www.linkedin.com/in/jayant-bhardwaj-8bb510145" title="LinkedIn"><i
                          class="fab fa-linkedin"></i><span class="menu-title sr-only">LinkedIn</span></a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="https://www.behance.net/templateflip" title="Behance"><i class="fab fa-behance"></i><span class="menu-title sr-only">Behance</span></a></li> -->
                  </ul>
                </nav>
              </div>
              <div class="mt-3" data-aos="fade-up" data-aos-delay="200"><a
                  class="btn btn-primary shadow-sm mt-1 hover-effect" style="background-color: #EF0454; color: white; border-color: white;" href="#contact">Get In Touch <i
                    class="fas fa-arrow-right"></i></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave-bg"></div>
  </header>