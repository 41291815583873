<div class="section pt-4 px-3 px-lg-4" id="about">
    <div class="container-narrow">
      <div class="text-center mb-5">
        <h2 class="marker marker-center">About me</h2>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h2 class="h4 my-2">Hello! I’m Jayant Bhardwaj.</h2>
          <p><b>I have <b>7+ years</b>  of experience as Full Stack Developer. I am skilled in software development using Microsoft
            technologies such as ASP.NET, MVC, WebAPI, SQL, Javascript much more. I am a quick learner and a team worker that
            gets the job done. I can easily capitalize on low hanging fruits and quickly maximize timely deliverables
            for real-time schemas.</b></p>
          <div class="row mt-3">
            <div class="col-sm-2">
              <div class="pb-1">Age:</div>
            </div>
            <div class="col-sm-10">
              <div class="pb-1 fw-bolder">29</div>
            </div>
            <div class="col-sm-2">
              <div class="pb-1">Email:</div>
            </div>
            <div class="col-sm-10">
              <div class="pb-1 fw-bolder">jayant@jayantbhardwaj.com; jayant.sne@gmail.com</div>
            </div>
            <div class="col-sm-2">
              <div class="pb-1">Skype:</div>
            </div>
            <div class="col-sm-10">
              <div class="pb-1 fw-bolder">jbhardwaj2019@outlook.com</div>
            </div>
            <div class="col-sm-2">
              <div class="pb-1">Phone:</div>
            </div>
            <div class="col-sm-10">
              <div class="pb-1 fw-bolder">+91 7014268361, 7426021016</div>
            </div>
            <div class="col-sm-2">
              <div class="pb-1">Address:</div>
            </div>
            <div class="col-sm-10">
              <div class="pb-1 fw-bolder">Jaipur, India</div>
            </div>
            <div class="col-sm-2">
              <div class="pb-1">Staus:</div>
            </div>
            <div class="col-sm-10">
              <div class="pb-1 fw-bolder">Available</div>
            </div>
            <div class="col-sm-10">
                <a class="btn btn-primary mt-2" (click)="download()"  style="background-color: #EF0454; color: white; border-color: white;" > <span class="btn-icon"><i class="fa fa-download"></i></span> Download Resume</a>
            </div>
          </div>
        </div>
        <div class="col-md-5 offset-md-1" data-aos="fade-left" data-aos-delay="100"><img class="avatar img-fluid mt-2"
            src="../../assets/images/ab-img.png" width="90%" height="90%" alt="Walter Patterson" /></div>
      </div>
    </div>
  </div>