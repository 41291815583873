<header class="bg-light">
  <nav class="navbar navbar-expand-lg navbar-light bg-light" id="header-nav" role="navigation">
    <div class="container"><a class="link-dark navbar-brand site-title mb-0 newlink" href="#">My PortFolio</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span
          class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto me-2">
          <li class="nav-item"><a class="nav-link newlink" href="#about">About</a></li>
          <li class="nav-item"><a class="nav-link newlink" href="#services">Services</a></li>
          <li class="nav-item"><a class="nav-link newlink" href="#skills">Skills</a></li>
          <li class="nav-item"><a class="nav-link newlink" href="#portfolio">Portfolio</a></li>
          <li class="nav-item"><a class="nav-link newlink" href="#experience">Experience</a></li>
          <!-- <li class="nav-item"><a (click)="download()"   class="nav-link" >Resume</a></li> -->
          <li class="nav-item"><a  class="nav-link newlink" href="#contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
</header>
