<app-header></app-header>


<div id="my-scrollbar"  class="page-content">
    <div id="content">
        

    <app-headersecond></app-headersecond>
    <app-about></app-about>
    <app-services></app-services>
    <app-skills></app-skills>
    <!-- <app-portfolio></app-portfolio> -->
    <app-experience></app-experience>
    <app-testimonials></app-testimonials>
    <!-- <app-about></app-about> -->
    <app-contactme></app-contactme>
    <app-footer></app-footer>
    

        
    </div>
</div>
<!-- <router-outlet></router-outlet> -->


